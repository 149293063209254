// imports

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,700&display=swap');

// end of imports

// Variables
$main-grey: #ccc;
$light-grey: #999;
$inbetween-grey: #bbb;
$dark-grey: #555;
$main-blue: #5582de;
$main-yellow: #ffb100;
$button-green: #86C46A;
$button-red: #D85946;
$button-green-hover: #519e3f;

$head-font: 'Montserrat', sans-serif;
$body-font: 'Open Sans', sans-serif;

$base-shad: 0px 2px 4px rgba(0,0,0,0.2);
$hover-shad: 0px 4px 6px rgba(0,0,0,0.1);

// General page styling
body {
  margin: 0;
  padding: 0;
  background-color: $main-blue;
  font-family: $body-font;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
h1 {
  font-family: $head-font;
}
.spacer {
  height: 20px;
}
.loginMain {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .big-logo {
    background-image: url('../img/gasapp-logo-large.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 120px;
    height: 50px;
    margin: 0 auto 20px;
  }
}

// Login box styling
.login-box {
  // background-color: rgba(255, 255, 255, 0.1); // Slight transparency ??
  border: 1px solid white;
  border-radius: 5px;
  padding: 30px;
  width: 100%;
  max-width: 500px;
  box-shadow: $base-shad;
}



.login-field {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  background-color: #fff;
  // color: white;
  font-size: 16px;
  box-sizing: border-box;
  font-family:$body-font;

  &:focus {
    outline: none;
    border-color: $main-yellow;
  }
}

.login-label {
  display: block;
  margin-bottom: 5px;
  font-size: 18px;
  text-align: left;
  font-family: $head-font;
  font-weight:800;
}

// Button styling
.ga-button {
  background-color: $button-green;
  color: white;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  font-weight:800;

  &:hover {
    background-color: $button-green-hover;
  }
}


select {
  border-color:#C7C7C7;
  border-radius:5px;
  padding: 5px 20px 5px 20px;
  font-family:$body-font;
  font-size:18px;
}


//HEADER
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $main-blue;
  padding: 10px;
  width: 90%;
  position: fixed;
  top: 0;
  left: 5%;
  z-index: 10;
  border-bottom: 1px solid white;
  box-sizing:border-box;

  .menu-icon, .logout-icon {
    color: white;
    font-size: 24px;
    cursor: pointer;
  }

  .big-logo {
    background-image: url('../img/gasapp-logo-large.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 120px;
    height: 50px;
  }
}

/* Hidden menu */
.menu {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: $main-blue;
  margin-top: 61px; /* Adjust for header height */
   background-color: rgba(0,0,0,0.5);
   text-align: left;
   // padding: 20px;
  z-index: 100;

  .menu-link {
    // height: 50px;
    padding:18px;
    border-bottom:1px solid #fff;
    width:87%;
    margin:0 auto;
    &:hover {
      background-color: rgba(0,0,0,0.2);
    }
  }

  a {
    color: white;
    padding: 10px;

    text-decoration: none;
    font-size: 18px;
     transition: background-color 0.3s ease;


  }
}
/* Dashboard content styles */
.dashboard-content {
  margin-top: 80px;
  padding: 10px;
  h1 {
    font-size: 24px;
    margin: 10px 0;
  }

  .stat-item {
    font-size: 18px;
    margin: 10px 0;
  }
}


/////// GAS CARDS \\\\\\\
.gas-cards {
  padding: 10px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

  .gas-cards-content {
    margin-top: 80px;
     h1 {
      font-size: 24px;
      margin: 10px 0;
    }


    .cards {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;

      .ga-button {
        width: 100%;
      }
    }


    .number-cards {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;

    }

    .photo-card {
      position: relative;
      width: 100%;
      overflow: hidden;
      border-radius: 5px;
      // border: 1px solid #000;
      background-color: #fff;
      color:#000;
      padding-top:10px;
      > div {
        // display:inline-block;
        text-align:left;
        margin-left:16px;
      }
    }


    .issuedetail {
      background: #FFFFFF;
      color: black;
      padding: 12px;
      text-align: left;
      .title {
        font-weight:800;

      }
    }


    .number-card {
      position: relative;
      width: 100%;
      padding-top: 20px;
      border: 1px solid white;
      box-shadow: $base-shad;

      border-radius: 5px;

      .button-row {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin: 20px;

        .ga-button {
          flex: 1;  /* Make buttons take up equal space */
          padding: 10px 10px;
          border-radius: 3px;
          font-size: 16px;
          color: white;
          text-align: center;
          cursor: pointer;
        }

      }
    }
    .number-card div {
      font-size: 18px;
     }

    .number-card .approve, .number-card .reject {
      padding: 5px 10px;
      border-radius: 3px;
      font-size: 14px;
      color: white;
      text-align: center;
      cursor: pointer;
    }
    .number-card .copy {
      color: $main-blue;
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
    }

    .photo-card img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top:10px;
    }

    .approve, .reject {
      position: absolute;
      top: 10px;
      left: 10px;
      padding: 8px 24px;
      border-radius: 5px;
      font-size: 18px;
      color: white;
      font-weight:800;
      cursor:pointer;
    }

    .approve-low, .reject-low {
      position: absolute;
      bottom: 10px;
      left: 10px;
      padding: 8px 24px;
      border-radius: 5px;
      font-size: 18px;
      color: white;
      font-weight:800;
      cursor:pointer;
    }


    .approve {
      background-color: $button-green;  /* Green background for approve */
    }

    .reject {
      background-color: $button-red;  /* Red background for reject */
    }

    .red {
      background-color: $button-red;  /* Red background for dre red tings */
    }

    .approve-low {
      background-color: $button-green;  /* Green background for approve */
    }

    .reject-low {
      background-color: $button-red;  /* Red background for reject */
    }
  }
}


/////// DASHBOARD \\\\\\\
.dashboard {
  padding: 10px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-size: 24px;
    margin: 12px 0;
  }

  .dashboard-section {
    margin-bottom: 20px;
    border: 1px solid white;
    padding: 10px;
    width:40%;
    margin:2%;
    display: inline-block;
    box-sizing: border-box;
    background-color:#fff;
    border-radius: 5px;
    h1, .stat-item {
      color:#000;
    }
    h1 {
      font-family:montserrat;
    }

    .red-dot {
      float: left;
       top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      background-color: $button-red;
      border-radius: 50%;
    }
  }

  .stat-item {
    font-size: 18px;
    margin: 10px 0;
  }



  .logout-button {
    margin-top: 30px;
    cursor: pointer;
    background-color: $button-red;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;

    &:hover {
      background-color: darken($button-red, 10%);
    }
  }
}



.users-list {
  width: 100%;
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.user-row {
  padding: 12px 16px;
  text-align: left;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap; // Allow wrapping
  align-items: center;
  gap: 8px; // Adds spacing between items
  border-bottom: 1px solid #ddd;
  transition: background 0.2s ease-in-out;
  word-break: break-word; // Ensures long words wrap
}

.user-row:last-child {
  border-bottom: none;
}
gas-card-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.gas-card-image {
  width: 100%;
  max-width: 300px; /* Adjust size */
  height: auto;
  border-radius: 8px;
  margin-top: 24px;
}

// Responsiveness
@media (max-width: 600px) {
  .login-box {
    padding: 20px; // Adjust padding for smaller screens
    max-width: 100%;
  }

  .login-field {
    font-size: 14px;
  }

  .ga-button {
    padding: 10px;
  }

  .dashboard {
    padding: 10px;

    h1 {
      font-size: 20px;
    }

    .stat-item {
      font-size: 16px;
    }

    a, .logout-button {
      padding: 8px 15px;
      font-size: 14px;
    }
    .dashboard-section {
      width:90%
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $main-blue;
    width: 96%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    .menu-icon, .logout-icon {
      color: white;
      font-size: 24px;
      cursor: pointer;
    }

    .big-logo {
      background-image: url('../img/gasapp-logo-large.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 120px;
      height: 50px;
    }
  }


  .menu {
    a {
      font-size: 16px;
    }
  }

  .dashboard-content {
    padding: 15px;

    h1 {
      font-size: 20px;
    }

    .stat-item {
      font-size: 16px;
    }
  }
}
