.simple-alert {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: none;
    align-items: center;
    z-index: 999; }
.simple-alert.open {
    display: flex; }
.simple-alert .alert-padding {
    flex: 1 1; }
.simple-alert .alert-modal {
    max-width: 250px;
    background: #FFF;
    color: black;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center; }
.simple-alert .alert-modal .modal-title {
    font-weight: bold;
    display: block;
    font-size: large; }
.simple-alert .alert-modal .modal-message {
    display: block;
    margin: 5px 0 15px 0; }
.simple-alert .alert-modal .modal-button {
    display: block;
    margin-top: 5px;
    border-radius: 999px;
    background-color: #DDD;
    font-weight: bold;
    padding: 10px;
    cursor: pointer; }
.simple-alert .alert-modal .modal-button:hover {
    background-color: #EEE; }
.simple-alert .alert-modal .modal-button.success {
    background-color: #519e3f;
    color: white; }
.simple-alert .alert-modal .modal-button.success:hover {
    background-color: #68c153; }
.simple-alert .alert-modal .modal-button.danger {
    background-color: #CC0000;
    color: white; }
.simple-alert .alert-modal .modal-button.danger:hover {
    background-color: #EE0000; }

/*# sourceMappingURL=simple-alert-dialog.css.map */
